var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "photo-items"
  }, [_vm._l(_vm.notices, function (board, index) {
    return _c('div', {
      key: `notice-${index}`,
      staticClass: "photo-item"
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.showBoard(board);
        }
      }
    }, [_c('div', {
      staticClass: "photo-item__inner"
    }, [_c('div', {
      staticClass: "photo-item__img"
    }, [_c('div', {
      staticClass: "photo-item__img-inner",
      style: `background-image:url(${board.thumb});`
    })]), _c('div', {
      staticClass: "photo-item__con"
    }, [_c('h2', {
      staticClass: "photo-item__tit"
    }, [_vm._v(_vm._s(board.subject))]), _c('p', {
      staticClass: "photo-item__txt"
    }, [_vm._v(_vm._s(board.summary))]), _c('div', {
      staticClass: "photo-item__con-bottom"
    }, [_c('span', {
      staticClass: "photo-item__date"
    }, [_vm._v(_vm._s(board.createdAt.toDate()))]), _c('span', {
      staticClass: "photo-item__hit"
    }, [_vm._v("조회수 : " + _vm._s(board.viewCount))])])])])])]);
  }), _vm._l(_vm.boards, function (board, index) {
    return _c('div', {
      key: `board-${index}`,
      staticClass: "photo-item"
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.showBoard(board);
        }
      }
    }, [_c('div', {
      staticClass: "photo-item__inner"
    }, [_c('div', {
      staticClass: "photo-item__img"
    }, [_c('div', {
      staticClass: "photo-item__img-inner",
      style: `background-image:url(${board.thumb});`
    })]), _c('div', {
      staticClass: "photo-item__con"
    }, [_c('h2', {
      staticClass: "photo-item__tit"
    }, [_vm._v(_vm._s(board.subject))]), _c('p', {
      staticClass: "photo-item__txt"
    }, [_vm._v(_vm._s(board.summary))]), _c('div', {
      staticClass: "photo-item__con-bottom"
    }, [_c('span', {
      staticClass: "photo-item__date"
    }, [_vm._v(_vm._s(board.createdAt.toDate()))]), _c('span', {
      staticClass: "photo-item__hit"
    }, [_vm._v("조회수 : " + _vm._s(board.viewCount))])])])])])]);
  })], 2), _c('div', {
    staticClass: "board-bottom"
  }, [_c('pagination-component', {
    attrs: {
      "count": _vm.count,
      "value": _vm.page
    },
    on: {
      "change": _vm.changePage
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }