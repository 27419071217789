<template>
    <div class="board-container">
        <div class="photo-items">
            <div v-for="(board, index) in notices" :key="`notice-${index}`" class="photo-item">
                <a href="#" @click="showBoard(board)">
                    <div class="photo-item__inner">
                        <div class="photo-item__img"><div class="photo-item__img-inner" :style="`background-image:url(${board.thumb});`"></div></div>
                        <div class="photo-item__con">
                            <h2 class="photo-item__tit">{{ board.subject }}</h2>
                            <p class="photo-item__txt">{{ board.summary }}</p>
                            <div class="photo-item__con-bottom">
                                <span class="photo-item__date">{{ board.createdAt.toDate() }}</span
                                ><span class="photo-item__hit">조회수 : {{ board.viewCount }}</span>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div v-for="(board, index) in boards" :key="`board-${index}`" class="photo-item">
                <a href="#" @click="showBoard(board)">
                    <div class="photo-item__inner">
                        <div class="photo-item__img"><div class="photo-item__img-inner" :style="`background-image:url(${board.thumb});`"></div></div>
                        <div class="photo-item__con">
                            <h2 class="photo-item__tit">{{ board.subject }}</h2>
                            <p class="photo-item__txt">{{ board.summary }}</p>
                            <div class="photo-item__con-bottom">
                                <span class="photo-item__date">{{ board.createdAt.toDate() }}</span
                                ><span class="photo-item__hit">조회수 : {{ board.viewCount }}</span>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div class="board-bottom">
            <pagination-component :count="count" :value="page" @change="changePage" />
            <!-- <div class="board-buttons board-buttons--center">
                <a v-if="boards.length < summary.totalCount" class="button button--border" role="button" @click="$emit('loadmore')"><span>더보기</span></a>
                <a class="button button--primary" role="link" @click="$router.push('?mode=input')"><span>글쓰기</span></a>
            </div> -->
        </div>
    </div>
</template>

<script>
import paginationComponent from "@/components/client/control/pagination-component.vue";

export default {
    components: { paginationComponent },

    props: {
        code: { type: String, default: null },
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },
        notices: { type: Array, default: null },
        page: { type: Number, default: 1 },
        skip: { type: Number, default: 0 },
        limit: { type: Number, default: null },
    },
    data() {
        return {
            qna: this.$route.query.qna || undefined,
        };
    },
    methods: {
        changePage(page) {
            this.$emit("page", page);
        },
        showBoard(board) {
            this.$router.push(`${this.$route.path}/${board._id}`);
        },
    },
    computed: {
        count() {
            return Math.ceil(this.summary.totalCount / this.limit);
        },
    },
};
</script>
